// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-lib-templates-products-products-tsx": () => import("./../../../src/lib/templates/products/products.tsx" /* webpackChunkName: "component---src-lib-templates-products-products-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-coverage-areas-luzon-tsx": () => import("./../../../src/pages/coverage-areas/luzon.tsx" /* webpackChunkName: "component---src-pages-coverage-areas-luzon-tsx" */),
  "component---src-pages-coverage-areas-metro-manila-tsx": () => import("./../../../src/pages/coverage-areas/metro-manila.tsx" /* webpackChunkName: "component---src-pages-coverage-areas-metro-manila-tsx" */),
  "component---src-pages-coverage-areas-mindanao-tsx": () => import("./../../../src/pages/coverage-areas/mindanao.tsx" /* webpackChunkName: "component---src-pages-coverage-areas-mindanao-tsx" */),
  "component---src-pages-coverage-areas-retail-outlet-tsx": () => import("./../../../src/pages/coverage-areas/retail-outlet.tsx" /* webpackChunkName: "component---src-pages-coverage-areas-retail-outlet-tsx" */),
  "component---src-pages-coverage-areas-summary-tsx": () => import("./../../../src/pages/coverage-areas/summary.tsx" /* webpackChunkName: "component---src-pages-coverage-areas-summary-tsx" */),
  "component---src-pages-coverage-areas-visayas-tsx": () => import("./../../../src/pages/coverage-areas/visayas.tsx" /* webpackChunkName: "component---src-pages-coverage-areas-visayas-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

